<script setup>
const route = useRoute();
const { data, error } = await useCmsFetch(`entry/hub/${route.params.slug}`);

const { setGlobalTheme } = useTheme();
setGlobalTheme(data.value.theme);

const { breadcrumbs } = useTTCState();

onMounted(() => {
  breadcrumbs.value = data.value.breadcrumbs;
});

onBeforeUnmount(() => {
  breadcrumbs.value = null;
});

useHead({
  title: data.value?.seoTitle + " | .the tech collective",

  meta: [
    { property: "og:title", content: data.value?.seoTitle },
    { name: "image", property: "og:image", content: data.value?.seoImage },
    { name: "description", content: data.value?.seoDescription },
    { name: "robots", content: data.value?.seoRobots },
  ],
  link: [{ rel: "canonical", href: data.value?.seoCanonical }].filter(
    (item) => item.href,
  ),
});
</script>

<template>
  <div v-if="data">
    <section-hero-standard
      :heading="data.heading || data.title"
      :body="data.body"
      :media="data.media"
    />
    <component-sections v-if="data" :sections="data.sections" />
    <!-- <code>
      <pre>{{ data }}</pre>
    </code> -->
  </div>
</template>

<style></style>
